


.review-panel{

    // height: 360px;
    max-height: 360px;
    padding: 16px;
    

    border-radius: 12px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);

    overflow: hidden;
    position: relative;

    transition: max-height .2s;

    &_showmore{
        // max-height: 722px;
        padding-bottom: calc(16px + 24px);
    }


    &__title{
        color: var(--Gray-900, #101828);
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__item{

        &__label{
            color: var(--Gray-500, #667085);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }

        &__value{
            color: var(--Gray-900, #101828);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            min-height: 24px;
        }


    }

    &__itemgroup{
        
        &__title{
            color: var(--Gray-700, #344054);
            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

    }

    &__more{
        padding: 8px 0px;

        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;

        background: #FFF;
        /* Shadow/shadow top */
        box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.05);

        cursor: pointer;

        &__label{
            color: var(--Light-Green-500, #4C877F);
            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
        }
    }

    &__empty{
        color: var(--Gray-700, #344054);
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }


    
}





.notification{

    &_analysing{
        color: var(--Gray-500, #667085);
        /* Text sm/Regular italic */
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}
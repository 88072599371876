
.inviter{
    &-container{
        width: 100%;
        height: 100%;
    }

    background: #fff;

    &__body{
        width: 600px;
        padding: 24px 24px 0px 24px;
        box-sizing: border-box;
    }

    &__footer{
        padding: 32px 24px 24px 24px;
    }

    &__title{
        color: var(--Gray-900, #101828);
        text-align: center;

        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__tips{

        width: 552px;
        padding: 16px;
        box-sizing: border-box;

        border-radius: 12px;
        border: 1px solid var(--Light-Green-300, #79D0C5);
        background: var(--Light-Green-15, #F2FAF9);

        color: var(--Light-Green-600, #36625D);

        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

    &__exhibits{
        &-scrollview{
            max-height: 336px;
            overflow-y: scroll;
            scrollbar-width: thin;
            box-sizing: border-box;
        }

        border-radius: 12px;
        border: 1px solid var(--Gray-200, #EAECF0);

        &__header{
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            padding: var(--spacing-lg, 12px) 16px;
            background: var(--Base-White, #FFF);
            border-bottom: 1px solid var(--Gray-200, #EAECF0);
        }

        &__item{

            padding: 16px;
            box-sizing: border-box;
            border-bottom: 1px solid var(--Gray-200, #EAECF0);

            &_last{
                border-bottom: unset;
            }

            &__label{
                display: inline-block;
                vertical-align: middle;

                width: 468px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
    
                color: var(--Gray-700, #344054);
    
                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }


            
            
        }
    }
}



.client-profile{
    height: 100%;
    background: var(--Gray-neutral-25, #FCFCFD);

    &__container{
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__goback{
        position: absolute;
        left: 16;
        top: 16;
    }

    &__header{
        background: var(--Base-White, #FFF);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    &__info{
        padding: 48px 60px 32px 48px;
    }

    &__avatar{
        display: flex;
        padding: 26px;
        justify-content: center;
        align-items: center;

        border-radius: 200px;
        border: 4px solid var(--Colors-Base-white, #FFF);
        background: var(--Light-Green-15, #F2FAF9);

        /* Shadow/lg */
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }

    &__name{
        color: var(--Gray-900, #101828);
        /* Text xl/Bold */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 150% */
    }

    &__details{
        // width: 736px;
    }
    &__detail{
        width: 184px;
        overflow: hidden;
        
        &__label{
            color: var(--Gray-600, #475467);
            /* Text sm / Bold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
        }

        &__value{
            color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }

    &__body{
        min-height: 0
    }

    &__tabs{
        padding: 12px 48px 0px 48px;
        &__content{

        }
    }



}
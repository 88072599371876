


.divider{

    &__block{
        height: 1px;
        background: var(--Gray-200, #EAECF0);
    }

    &__label{
        color: var(--Gray-600, #475467);
        text-align: center;
        /* Text sm/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
}
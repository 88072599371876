
.lawyer{

    &__title{
        color: var(--Gray-700, #344054);
        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

}


.lawyer-form{

    padding: 24px;

    border-radius: 12px;
    background: var(--Gray-100, #F2F4F7);
    /* input focus shadow */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E4F6F3;

    &__header{

    }

    &__title{
        color: var(--Gray-700, #344054);

        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

    &__body{

    }
}

.lawyer__empty{

    &__title{

        text-align: center;

        color: var(--Gray-800, #1D2939);
        /* Text lg/Bold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
    }   

    &__subtitle{

        text-align: center;

        color: var(--Gray-700, #344054);
        text-align: center;
        
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
}


.lawyer__item{

    padding: 24px;

    border-radius: 12px;
    border: 1.5px solid transparent;
    background: var(--Gray-100, #F2F4F7);

    cursor: pointer;

    transition: background-color .2s, border .2s;

    &_selected{
        border-radius: 12px;
        border: 1.5px solid var(--Light-Green-400, #63ABA2);
        background: var(--Light-Green-15, #F2FAF9);
    }

    &:hover{
        border-radius: 12px;
        background: var(--Light-Green-15, #F2FAF9);
    }

    &__title{
        color: var(--Gray-700, #344054);

        /* Text lg/Medium italic */
        font-family: Inter;
        font-size: 18px;
        font-style: italic;
        font-weight: 500;
        line-height: 28px;
    }

    &__name{
        color: var(--Gray-700, #344054);
        /* Text lg/Bold italic */
        font-family: Inter;
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
    }

    &__detail{

        &__label{
            color: var(--Gray-600, #475467);
            /* Text md/Medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
        }

        &__value{
            color: var(--Gray-600, #475467);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }

}

.lawyer__list__create-label{
    color: var(--Light-Green-500, #4C877F);
    /* Text lg/Semibold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */

    cursor: pointer;
}
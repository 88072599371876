

.guideline{
    padding: 12px;
    background: #fff;
    width: 280px;
    box-sizing: border-box;

    &__img{
        width: 256px;
    }

    &__label{
        color: var(--Gray-700, #344054);
        /* Text xs/Regular */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }
}


.generate-evidence{

    &__inprogress{
        color: var(--Gray-500, #667085);
        /* Text sm/Regular italic */
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    &__item{
        
        &__title{
            color: var(--Gray-700, #344054);

            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }
    }
}

.strength_tags__table{

    &__wrapper{
        border-radius: 12px;
        border: 1px solid var(--Light-Green-100, #AFE3DC);
        overflow: hidden;
    }
    
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 12px;
    // border: 1px solid var(--Light-Green-100, #AFE3DC);
    background: var(--Base-White, #FFF);

}

.strength_tags__table td{
    padding: 10px var(--spacing-lg, 12px);
    
    color: var(--Gray-700, #344054);
    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    
    border: 1px solid var(--Light-Green-100, #AFE3DC);
}

.strength_tags__table thead td{
    background: var(--Light-Green-15, #F2FAF9);
    border-top: 0px;
}

.strength_tags__table tbody tr:last-child td{
    border-bottom: 0px;
}

.strength_tags__table tr td:first-child{
    width: 217px;
    background: var(--Light-Green-15, #F2FAF9);
    border-left: 0px;
}

.strength_tags__table tr td:last-child{
    border-right: 0px;
}

.recommender-group{

    padding: 16px;

    border-radius: 12px;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Base-White, #FFF);
    white-space: normal;

    &__title{
        color: var(--Gray-700, #344054);
        /* Text md/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
    }

    &__more{
        max-height: 0;
        transition: max-height .3s;
        overflow: hidden;
    }
}

.recommender{

    &__no{
        color: var(--Gray-700, #344054);
        text-align: right;
        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

    &__name,
    &__employer
    {
        &__label{
            font-weight: 600;
        }

        color: var(--Gray-700, #344054);

        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &__papers{
        padding: 16px;
        border-radius: 8px;
        background: var(--Gray-neutral-50, #F9FAFB);

        position: relative;
        overflow: hidden;

        &__more{
            max-height: 0;
            transition: max-height .2s;
            overflow: hidden;
        }

        &__morebtn{
            position: absolute;
            right: 16px;
            bottom: 16px;

            color: var(--Light-Green-500, #4C877F);
            /* Text xs/Semibold */
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 150% */

            cursor: pointer;
        }
    }

    &__paper{
        
        &__label{
            color: var(--Light-Green-500, #4C877F);
            font-weight: 600;
        }

        color: var(--Gray-700, #344054);
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

    }

    &__paper-divider{
        height: 1px;
        background: var(--Gray-neutral-300, #D2D6DB);
    }

    
}


.tag{
    display: inline-block;
    padding: 2px 8px;
    border-radius: 16px;
    background: var(--Gray-100, #F2F4F7);
    mix-blend-mode: multiply;

    color: var(--Gray-700, #344054);
    text-align: center;

    /* Text xs/Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */

    cursor: pointer;
}


.tag-details{

    &__wrapper{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 100px 240px 350px 240px;
        box-sizing: border-box;
    }

    box-sizing: border-box;
    width: 100%;
    
    background: var(--Base-White, #FFF);
    max-height: 70vh;
    overflow-y: auto;
    scrollbar-width: thin;
    position: relative;

    &__header{
        padding: 12px 24px;
        position: sticky;
        left: 0;
        right: 0;
        top: 0;
        background: var(--Base-White, #FFF);
    }

    &__title{
        
        color: var(--Gray-900, #101828);
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__body{
        padding: 12px 24px;
        padding-top: 0;
    }

    &__strengths li{
        margin-bottom: 16px;
    }

    &__statement{
        &__label{
            color: var(--Gray-600, #475467);
            /* Text sm / Bold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            text-decoration: underline;
        }
        &__content{
            color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            word-break: break-all;
            
        }
    }

    &__evidence{
        &__label{
            color: var(--Gray-600, #475467);
            /* Text sm / Bold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            text-decoration: underline;
        }
        &__content{
            display: inline-block;
            color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
        &__source{
            padding-left: 4px;
            // color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }


}
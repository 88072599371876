

.table{
    // border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 12px;
    border: 1px solid var(--Gray-200, #EAECF0);

    background: var(--Base-White, #FFF);

    &_with-caption{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    
    &-container{
        border-radius: 12px;
        border: 1px solid var(--Gray-200, #EAECF0);
        background: #FFF;
    }

    &__row{
        &_expand{
            background: var(--Gray-25, #FCFCFD);
            &:hover{
                background: var(--Gray-100, #F2F4F7);
                cursor: pointer;
            }
        }
        &_footer{
            background: var(--Gray-25, #FCFCFD);
        }
        &_empty{
            color: var(--Gray-400, #98A2B3);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }
    }
}

.table caption{
    padding: 12px 24px;
    border-radius: 12px 12px 0 0;
    border-top: 1px solid var(--Gray-200, #EAECF0);
    border-left: 1px solid var(--Gray-200, #EAECF0);
    border-right: 1px solid var(--Gray-200, #EAECF0);

    background: #fff;
}

.table tbody tr{
    // display: flex;
    // border: 1px solid var(--Gray-200, #EAECF0);
    cursor: pointer;
    &:hover{
        background: var(--Gray-100, #F2F4F7);
    }
}


.table th{
    text-align: left;
    padding: 12px 24px;

    color: var(--Gray-600, #475467);
    /* Text sm/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.table td{
    // flex: 1;
    text-align: left;
    padding: 16px 24px;

    color: var(--Gray-700, #344054);
    /* Text sm/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}


// border-bottom: 1px solid var(--Gray-200, #EAECF0);
// background: #FFF;
.table th,td{
    // border-right: 1px solid var(--Gray-200, #EAECF0);
    border-bottom: 1px solid var(--Gray-200, #EAECF0);
}


.table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 12px;
}
  
.table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 12px;
}




.tabs{
    position: relative;

    &__item{
        display: flex;
        box-sizing: border-box;
        min-width: 81px;
        padding: 16px var(--spacing-xs, 4px) var(--spacing-lg, 12px) var(--spacing-xs, 4px);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-sm, 6px);

        color: var(--Gray-500, #667085);
        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */

        cursor: pointer;

        &_activate{
            color: var(--Gray-900, #101828);
            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

        transition: color .2s;
    }
    
    &__highlight{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;

        &__bar{
            height: 2px;
            background: #63ABA2;
        }
    }
}
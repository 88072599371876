

.mask{
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: rgba(255, 255, 255, 0);
    visibility: hidden;

    &_opened{
        visibility: visible;
        background: rgba(68, 75, 86, 0.70);
    }

    transition: background-color .1s, visibility .1s;

    &__container{
        height: 100%;
    }
}
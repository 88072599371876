.add-link{
    color: var(--Light-Green-500, #4C877F);

    /* Text md/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

    cursor: pointer;
}
.form{
    height: 100%;
    width: 100%;
    background: var(--Gray-neutral-25, #FCFCFD);
    

    &__header{
        padding: 20px 80px 20px 28px;
        height: 40px;

        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */

        /* Shadow/xs */
        background: var(--Base-White, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        z-index: 1;
    }

    &__wizard{
        height: 100%;
        width: 296px;

        background: var(--Colors-Base-white, #FFF);

        /* Shadow/lg */
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }

    &__content{
        height: 100%;
        padding: 24px 192px 48px 192px;
        box-sizing: border-box;
        overflow: scroll;
        scrollbar-width: thin;

        position: relative;
    }

    &__guide{
        width: 100%;
        padding: 24px 0 48px;
        overflow-y: scroll;
        scrollbar-width: thin;
    }
}


.wizard{
    height: 100%;
    display: flex;
    flex-direction: column;
    &__title{
        padding: 24px 0px 24px 28px;
        border-bottom: 1px solid var(--Gray-true-100, #F5F5F5);

        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__scrollview{
        flex: 1;
        min-height: 1;
        height: 1;
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    &__group{

        padding: 16px 10px 16px 28px;

        color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
        /* Text sm / Bold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    &__list{
        padding: 0px 16px;
        &_sub{
            padding: 0px 0px;
        }
        &__item{
            height: 48px;
            padding: 12px;
            box-sizing: border-box;
            border-radius: 8px;

            color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
            /* Text md/Medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */

            position: relative;
            cursor: pointer;

            &:hover{
                background: var(--Colors-Gray-true-50, #FAFAFA);
            }

            &_sub{
                // padding: 12px 0;
                padding-left: 32px;
            }

            &_activate{
                background: var(--Light-Green-15, #F2FAF9);
                color: var(--Light-Green-500, #4C877F);
            }

            &_finish{
                
            }

            &_disabled{
                border-radius: 8px;
                background: var(--Base-White, #FFF);
                color: var(--Gray-400, var(--colors-gray-light-mode-400, #98A2B3));
                /* Text md/Medium */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */

                &:hover{
                    background: var(--Base-White, #FFF);
                }
            }

            &__icon{
                position: absolute;
                right: 12px;
                width: 20px;
                height: 20px;

                &_nodropdown{
                    transform: rotate(0);
                    transition: transform .3s;
                }

                &_dropdown{
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__dropdown{
        overflow: hidden;
        max-height: 0;
        &_show{
            max-height: 620px;
        }

        transition: max-height .5s;
    }

}


.stepform{
    
    width: 760px;
    margin: auto;

    &__header{
        color: var(--Gray-900, #101828);
        /* Display sm/Semibold */
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 126.667% */
    }

    &__title{
        color: var(--Gray-900, #101828);
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */

        &__required{
            display: inline-block;
            color: var(--Error-500, #F04438);
        }
    }

    &__subtitle{
        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}


.notificationbar{

    &__wrapper{
        padding: 16px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;

        &_hidden{
            opacity: 0;
        }
    }


    padding: 16px;
    /* Text sm/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */

    border-radius: 12px;

    &_info{
        color: var(--Gray-700, #344054);
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--Base-White, #FFF);
    }

    &_warn{
        color: var(--Warning-700, #B54708);
        border: 1px solid var(--Warning-300, #FEC84B);
        background: var(--Warning-25, #FFFCF5);
    }

    &_success{
        color: var(--Success-700, #027A48);
        border: 1px solid var(--Success-300, #6CE9A6);
        background: var(--Success-25, #F6FEF9);
    }

    &_fail{
        color: var(--Error-700, #B42318);
        border: 1px solid var(--Error-300, #FDA29B);
        background: var(--Error-25, #FFFBFA);   
    }

    
}
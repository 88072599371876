


.login{
    width: 360px;
    padding-top: calc(184px / 960px * 100vh);

    &__title{
        color: var(--Gray-900, #101828);
        text-align: center;
        /* Display sm/Semibold */
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 126.667% */
    }

    &__subtitle{
        color: var(--Gray-600, #475467);
        text-align: center;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

    }

    &__signup{
        color: var(--Gray-600, #475467);
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}
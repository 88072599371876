


.quick-evaluation
{
    width: 100%;
    padding: 24px 48px;
    box-sizing: border-box;
    overflow-y: auto;
    scrollbar-width: thin;
    height: 100%;

    &__welcome{
        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}

.form-group{

    &__title{
        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text md/Bold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */

        &__required{
            color: var(--Error-500, var(--colors-gray-light-mode-700, #F04438));
            /* Text md/Bold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }
}
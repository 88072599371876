
.workbench{
    
    height: 100%;

    &__siderbar{
        height: 100%;
    }

    &__container{
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: thin;
    }
}

.siderbar{

    height: 100%;
    width: 80px;
    padding: 16px 12px;
    box-sizing: border-box;
    background: var(--Gray-neutral-800, #1F2A37);

    &__avatar{
        display: flex;
        width: 48px;
        height: 48px;
        justify-content: center;
        align-items: center;

        border-radius: 200px;
        background: var(--Light-Green-15, #F2FAF9);

    }

    &__divider{
        height: 1px;
        background: var(--Gray-200, #EAECF0);
    }
    
    &__toolbox{
        width: 240px;
        box-sizing: border-box;
        // display: inline-flex;
        // flex-direction: column;
        // align-items: flex-start;

        // border-radius: 8px;
        // border: 1px solid var(--Gray-200, #EAECF0);
        // background: var(--Base-White, #FFF);
        /* Shadow/lg */
        // box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

        &__profile{
            width: 240px;
            padding: 0px 10px;
            padding-bottom: 12px;
            box-sizing: border-box;
            border-bottom: 1px solid var(--Gray-200, #EAECF0);
            &__name{
                color: var(--Gray-700, #344054);
                /* Text sm/Semibold */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
            }

            &__email{
                color: var(--Gray-600, #475467);
                /* Text sm/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
            }
        }

        &__tools{
            // display: flex;
            width: 240px;
            padding: 4px 0px;
            box-sizing: border-box;
            // flex-direction: column;
            // align-items: flex-start;
            // align-self: stretch;
            // border-bottom: 1px solid var(--Gray-200, #EAECF0);
        }

        &__tool{
            
            padding: 9px 10px;
            border-radius: 6px;
            
            &:hover{
                background: var(--Gray-50, #F9FAFB);
            }

            transition: background-color .2s;
            cursor: pointer;

            &__label{
                color: var(--Gray-700, #344054);
                /* Text sm/Medium */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
            }
        }

    }
}

.menubar{
    
    &__item{
        
        // width: 48px;
        // padding: 12px;
        box-sizing: border-box;
        
        border-radius: 6px;
        background: var(--Gray-modern-800, #202939);

        &_activate{
            background: var(--Light-Green-700, #2B504B);
        }

        &:hover{
            background: var(--Light-Green-700, #2B504B);
        }

        &__icon{
            width: 48px;
            height: 48px;
            // background-position: center center;
            // background-size: 24px 24px;
            // background-repeat: no-repeat;

            &_chatbot{
                background: center / 24px 24px no-repeat url("./assets/images/message-smile-square.svg");
                &_activate, &:hover{
                    background: center / 24px 24px no-repeat url("./assets/images/message-smile-square-activate.svg");
                }
            }

            &_clientmanagement{
                background: center / 24px 24px no-repeat url("./assets/images/users-01.svg");
                &_activate, &:hover{
                    background: center / 24px 24px no-repeat url("./assets/images/users-01-activate.svg");
                }
            }

            &_smarttools{
                background: center / 24px 24px no-repeat url("./assets/images/smarttools.svg");
                &_activate, &:hover{
                    background: center / 24px 24px no-repeat url("./assets/images/smarttools-activate.svg");
                }
            }

        }

        transition: background-color .2s;
    }
}
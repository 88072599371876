

.chat-screen{
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    overflow-y: auto;
    scrollbar-width: thin;
    height: 100%;
}

.chat-message{

    &_text{
        color: var(--Base-Black, #000);
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
}

.rank-analyse{

    &__background{

    }
}

.rank-analyse__background{
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 12px;
    border: 1px solid var(--Light-Green-100, #AFE3DC);
    width: 100%;
}

.rank-analyse__background td{

    padding: 10px var(--spacing-lg, 12px);

    color: #000;
    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.rank-analyse__background tr td:first-child{
    background: var(--Light-Green-15, #F2FAF9);
}
.rank-analyse__background tr td:last-child{
    width: 350px;
}
.rank-analyse__background tr:first-child td:first-child{
    border-top-left-radius: 12px;
}
.rank-analyse__background tr:first-child td:last-child{
    border-top-right-radius: 12px;
}
.rank-analyse__background tr:last-child td:first-child{
    border-bottom-left-radius: 12px;
}
.rank-analyse__background tr:last-child td:last-child{
    border-bottom-right-radius: 12px;
}


.rank-analyse__result{
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 12px;
    border: 1px solid var(--Light-Green-100, #AFE3DC);
    width: 100%;
}

.rank-analyse__result td{

    padding: 10px var(--spacing-lg, 12px);
    box-sizing: border-box;
    color: #000;
    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.rank-analyse__result tr td:first-child{
    background: var(--Light-Green-15, #F2FAF9);
}
.rank-analyse__result tr td:nth-child(n + 2){
    width: 175px;
}
.rank-analyse__result tr:first-child td:first-child{
    border-top-left-radius: 12px;
}
.rank-analyse__result tr:first-child td:last-child{
    border-top-right-radius: 12px;
}
.rank-analyse__result tr:last-child td:first-child{
    border-bottom-left-radius: 12px;
}
.rank-analyse__result tr:last-child td:last-child{
    border-bottom-right-radius: 12px;
}
.rank-analyse__result thead tr{
    background: var(--Light-Green-15, #F2FAF9);
}




.footer{
    padding: 60px 80px 84px;
    background: var(--Base-Black, #000);
    
    &__logo{
        cursor: pointer;
        &__text{
            color: var(--Base-White, #FFF);
            /* Display xs/Bold */
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
        }
    }

    &__address, &__email{
        width: 320px;

        &__label{
            color: var(--Gray-50, #F9FAFB);
            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

        &__content{
            color: var(--Gray-200, #EAECF0);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }

    &__copyright{
        color: var(--Gray-true-500, #737373);
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }



}


.header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 24px 80px;
    height: 40px;
    z-index: 999;

    background-color: white;

    
    &__logo{
        cursor: pointer;
        &__text{
            color: #000;
            /* Display xs/Bold */
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
        }
    }
}
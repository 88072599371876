


.form{
    height: 100%;
    width: 100%;
    background: var(--Gray-neutral-25, #FCFCFD);

    &__header{
        padding: 20px 80px 20px 28px;
        height: 40px;

        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */

        /* Shadow/xs */
        background: var(--Base-White, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        z-index: 1;
    }
}


.left-screen{
    width: 750px;
    min-width: 750px;
    height: 100%;
    padding: 24px 28px;
    box-sizing: border-box;
    position: relative;
}
.right-screen{
    height: 100%;
    position: relative;
}
:root{
    --analysisbox-row-tag-text-width: 409px;
    --analysisbox-row-type-text-width: 434px;
}


@media screen and (max-width: 1280px) {
    .left-screen{
        width: 750px;
        min-width: 750px;
        height: 100%;
        padding: 24px 28px;
        box-sizing: border-box;
        position: relative;
    }
    .right-screen{
        height: 100%;
        position: relative;
    }
    :root{
        --analysisbox-row-tag-text-width: 409px;
        --analysisbox-row-type-text-width: 434px;
    }
}

@media screen and (min-width: 1440px) {
    .left-screen{
        width: 840px;
        min-width: 840px;
        height: 100%;
        padding: 24px 28px;
        box-sizing: border-box;
        position: relative;
    }
    .right-screen{
        height: 100%;
        position: relative;
    }
    :root{
        // --analysisbox-row-tag-text-width: 547px;
        // --analysisbox-row-type-text-width: 664px;
        --analysisbox-row-tag-text-width: 499px;
        --analysisbox-row-type-text-width: 524px;
    }
}


@media screen and (min-width: 1680px) {
    .left-screen{
        width: 980px;
        min-width: 980px;
        height: 100%;
        padding: 24px 28px;
        box-sizing: border-box;
        position: relative;
    }
    .right-screen{
        height: 100%;
        position: relative;
    }
    :root{
        // --analysisbox-row-tag-text-width: 687px;
        // --analysisbox-row-type-text-width: 804px;
        --analysisbox-row-tag-text-width: 639px;
        --analysisbox-row-type-text-width: 664px;
    }
}


@media screen and (min-width: 1920px) {
    .left-screen{
        width: 1120px;
        min-width: 1120px;
        height: 100%;
        padding: 24px 28px;
        box-sizing: border-box;
        position: relative;
    }
    .right-screen{
        height: 100%;
        position: relative;
    }
    :root{
        --analysisbox-row-tag-text-width: 779px;
        --analysisbox-row-type-text-width: 804px;
    }
}



.editor__processing{
    &__title{
        color: var(--Gray-700, #344054);

        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    &__subtitle{
        color: var(--Gray-500, #667085);

        /* Text sm/Regular italic */
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}

.editor__statusbar{
    &__label{
        color: var(--Gray-700, #344054);
        text-align: center;
        
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    &__status{
        display: inline-block;
        padding: 2px 10px;

        border-radius: 16px;
        mix-blend-mode: multiply;

        text-align: center;
        /* Text sm/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        
        &_NEEDED{
            background: var(--Error-50, #FEF3F2);
            color: var(--Error-700, #B42318);
        }
        &_PENDING{
            background: var(--Warning-50, #FFFAEB);
            color: var(--Warning-700, #B54708);
        }
        &_COMPLETED{
            background: var(--Success-50, #ECFDF3);
            color: var(--Success-700, #027A48);
        }
    }
}

.editor__analysisbox{
    width: 100%;
    min-height: 100%;
    border-radius: 12px;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Colors-Base-white, #FFF);

    box-sizing: border-box;

    &-wrapper{
        height: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        position: relative;
    }


    &__header{

    }
    &__label{

        &_type{
            display: inline-block;
            color: var(--Gray-600, #475467);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }
        &_status{
            display: inline-block;
            color: var(--Gray-600, #475467);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }
    }
    &__row{
        border-bottom: 1px solid var(--Gray-200, #EAECF0);
        box-sizing: border-box;
        height: 40px;
        &_tag{
            background: var(--Gray-50, #F9FAFB);
            &:hover{
                background: var(--Gray-200, #EAECF0);
            }
        }

        &_item{
            background: var(--Gray-50, #fff);
            &:hover{
                background: var(--Gray-200, #EAECF0);
            }
        }

        &__wrapper{
            overflow: hidden;
        }

        &__type{
            padding: 10px 24px;
            box-sizing: border-box;
            height: 40px;

            &_1{

                &__label{
                    color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
                    /* Text sm/Medium */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px; /* 142.857% */
                }
            }

            &_2{
                position: relative;
                padding-left: calc(48px - 24px);

                &__label{
                    width: var(--analysisbox-row-type-text-width);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
                    /* Text sm/Regular */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px; /* 142.857% */
                }
            }

            &_tag{
                padding-left: calc(77px - 24px);
                box-sizing: border-box;
                position: relative;
                
                &__label{

                    width: var(--analysisbox-row-tag-text-width);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
                    /* Text sm/Regular */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                }

                &__detail{
                    
                    position: absolute;
                    right: 0px;
                    padding: var(--spacing-xs, 4px) 8px;
                    background: var(--Gray-200, #EAECF0);


                    color: var(--Gray-600, #475467);
                    /* Text sm/Semibold */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 142.857% */

                    cursor: pointer;

                    &:hover{
                        border-radius: 8px;
                        background: var(--Gray-50, #F9FAFB);
                    }
                }
            }

            &__icon{
                transform: rotate(-90deg);
                &_expend{
                    transform: rotate(0);
                }
                transition: transform .2s;
            }

            &__highlight{
                &-wrapper{
                    position: absolute;
                    right: 0;
                }
                
                padding: var(--spacing-xs, 4px) 8px;
                background: var(--Gray-200, #EAECF0);

                color: var(--Gray-600, #475467);
                /* Text sm/Semibold */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */

                cursor: pointer;

                &:hover{
                    border-radius: 8px;
                    background: var(--Gray-50, #F9FAFB);
                }
            }
        }

        &__status{
            width: 160px;
            height: 40px;
            padding: var(--spacing-lg, 12px) 24px;
            box-sizing: border-box;

            &__label{
                padding: 2px 10px;
                height: 24px;
                line-height: 24px;
                box-sizing: border-box;

                &_NEEDED{
                    border-radius: 16px;
                    background: var(--Error-50, #FEF3F2);

                    color: var(--Error-700, #B42318);
                    /* Text sm/Medium */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;

                    white-space: nowrap;
                }
    
                &_PENDING{
                    border-radius: 16px;
                    background: var(--Warning-50, #FFFAEB);
                    mix-blend-mode: multiply;
                    
                    color: var(--Warning-700, #B54708);
                    /* Text sm/Medium */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                }
    
                &_COMPLETED{
                    border-radius: 16px;
                    background: var(--Success-50, #ECFDF3);
                    mix-blend-mode: multiply;

                    color: var(--Success-700, #027A48);
                    text-align: center;

                    /* Text sm/Medium */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }
    }

    &__statbar{
        padding: 8px var(--spacing-lg, 12px);
        border-radius: 8px;
        background: var(--Gray-900, #101828);
        max-width: 319px;
        box-sizing: border-box;
        margin: auto;
        &__stat{
            color: var(--Base-White, var(--Colors-Base-white, #FFF));
            text-align: center;
            
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
        &__divider{
            width: 1px;
            height: 12px;
            background: var(--Gray-500, #667085);
        }
        &__icon{

            border-radius: 8px;
            border: 1px solid var(--Base-Black, #000);
            background: var(--Base-Black, #000);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            box-sizing: border-box;

            color: var(--Base-White, var(--Colors-Base-white, #FFF));
            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */

            cursor: pointer;
        }
        
    }
}


.petition-letter-reader{
    
    height: 100%;

    &__header{
        padding: 8px 28px;
        background: var(--Base-Black, #000);
    }

    &__body{
        padding: 24px 28px;
        box-sizing: border-box;
        background: var(--Gray-500, #667085);
        overflow: scroll;
        scrollbar-width: thin;

        position: relative;

    }

    &__content{
        max-width: 750px;
        margin: auto;
        height: 100%;
    }

    &__label{
        color: var(--Base-White, var(--Colors-Base-white, #FFF));
        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

    &__time{
        color: var(--Gray-200, #EAECF0);
        /* Text xs/Regular */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }

    &__tools{
        position: fixed;
        right: calc(16px + 28px);
        bottom: calc(20px + 24px);

        width: 44px;
        padding: 8px var(--spacing-lg, 12px);
        box-sizing: border-box;

        border-radius: 8px;
        background: var(--Gray-900, #101828);


        &__pagination{
            color: var(--Gray-300, #D0D5DD);
            text-align: center;
            /* Text xs/Regular */
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
        }

        &__divide{
            width: 12px;
            height: 2px;
            background: var(--Gray-500, #667085);;
        }
    }
}

.asset-preview{

    // width: 600px;
    height: 100%;
    // padding-bottom: 48px;
    box-sizing: border-box;

    &__header{
        padding: 24px 28px 0px 28px;
        background-color: #fff;
    }

    &__title{
        color: var(--Gray-700, #344054);
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */

        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
        white-space: nowrap;
    }

    &__status{
        padding: 2px 10px;
        border-radius: 16px;
        box-sizing: border-box;
        &_NEEDED{
            border-radius: 16px;
            background: var(--Error-50, #FEF3F2);

            color: var(--Error-700, #B42318);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;

            white-space: nowrap;
        }

        &_PENDING{
            border-radius: 16px;
            background: var(--Warning-50, #FFFAEB);
            mix-blend-mode: multiply;
            
            color: var(--Warning-700, #B54708);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }

        &_COMPLETED{
            border-radius: 16px;
            background: var(--Success-50, #ECFDF3);
            mix-blend-mode: multiply;

            color: var(--Success-700, #027A48);
            text-align: center;

            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }

    }

    // border-top: 1px solid var(--Gray-200, #EAECF0);
    // border-left: 1px solid var(--Gray-200, #EAECF0);
    // background: var(--Colors-Base-white, #FFF);
    // box-shadow: -4px 0px 24px -4px rgba(16, 24, 40, 0.08), -4px 0px 24px -2px rgba(16, 24, 40, 0.04);
}
//
.evidence-preview{

    &__evidencebox{
        padding: 24px 28px;
        background: var(--Colors-Base-white, #FFF);
    }

    &-wrapper{
        // height: 20px;
        overflow: hidden;
        background: var(--Colors-Base-white, #FFF);
    }

    &__icon{
        &_hidden{
            transform: rotate(-90deg);
        }
        transition: transform .2s;
    }

    &__title{
        color: var(--Gray-700, #344054);
        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

    &__value{
        padding-left: 32px;

        color: var(--Gray-700, #344054);
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}


.exhibits-preview{
    height: 100%;
    position: relative;
    

    &__form{
        // min-height: 560px;
        padding: 24px 28px;
        padding-bottom: 72px;
        background: var(--Colors-Base-white, #FFF);
        box-sizing: border-box;
        position: relative;
    }
    &__btn-wrapper{

        padding: 16px 28px;
        box-sizing: border-box;

        border-top: 1px solid var(--Gray-200, #EAECF0);
        border-bottom: 1px solid var(--Gray-200, #EAECF0);
        background: var(--Gray-50, #F9FAFB);
    }

    &__btn{
        
    }   

    &__send2client{
        padding: 8px 14px;

        border-radius: 8px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--Colors-Base-white, #FFF);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    
}

.notification{
    width: 100%;
    padding: 16px;
    box-sizing: border-box;

    &-wrapper{
        position: absolute;
        left: 28px;
        right: 28px;
        top: 24px;
    }

    &_success{
        border-radius: 12px;
        border: 1px solid var(--Success-300, #6CE9A6);
        background: var(--Success-25, #F6FEF9);

        color: var(--Success-700, #027A48);
        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    &_letter-updating{
        padding: 16px;

        border-radius: 12px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--Colors-Base-white, #FFF);

        color: var(--Gray-700, #344054);
        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }
}

.criteria{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    padding: 8px;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 32px;
    box-sizing: border-box;
    background: white;

    &__header{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }

    &__title{
        color: var(--Gray-900, #101828);

        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

    &__subtitle{
        color: var(--Gray-700, #344054);

        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */

        &__hl{
            color: var(--Gray-700, #344054);
            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
    }

    &__item{
        display: flex;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        &__checkbox{
            display: flex;
            width: 20px;
            height: 20px;
            padding: 3px;
            justify-content: center;
            align-items: center;

            // border-radius: 6px;
            // border: 1px solid var(--Light-Green-400, #63ABA2);
            // background: var(--Light-Green-15, #F2FAF9);
        }

        &__description{
            align-self: stretch;

            color: var(--Gray-600, #475467);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */

            white-space: normal;
        }
    }

    &__footer{
        width: 100%;
        display: flex;
        padding-top: 48px;
        justify-content: flex-end;
        align-items: center;
    }
}



.text{
    
    &_ellipsis{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
    
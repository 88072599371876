
.smart-tools{
    padding: 32px;
    display: flex;
    // width: 1296px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    box-sizing: border-box;
    &__header{
        color: var(--Gray-900, #101828);
        /* Display xs/Medium */
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 133.333% */
    }
    &__content{

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px 16px;
    }

    &__item{
        display: flex;
        align-items: center;
        padding: 24px 16px;
        gap: 12px;
        flex: 1 0 0;

        cursor: pointer;

        border-radius: 12px;
        border: 1px solid var(--Gray-200, #EAECF0);
        background: var(--Base-White, #FFF);

        color: var(--Gray-700, #344054);
        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */

        &__icon{
            display: flex;
            padding: 4px;
            align-items: center;
            gap: 12px;

            border-radius: 5.714px;
            background: radial-gradient(50% 50% at 50% 50%, #CAEEE8 0%, #67A199 94%);
        }
        
        &_disable{
            background: var(--Base-White, #eee);
            &:hover{
                border: 1px solid var(--Gray-neutral-200, #E5E7EB);
                background: var(--Base-White, #eee);
            }
        }
        &_normal{
            &:hover{
                border: 1px solid var(--Gray-neutral-200, #E5E7EB);
                background: var(--Gray-neutral-100, #F3F4F6);
            }
        }
    }
}

.tool{
    height: 100%;
    display: flex;
    flex-direction: column;
    &__header{
        display: flex;
        padding: 32px 16px;
        gap: 12px;
        align-items: center;

        color: var(--Gray-900, #101828);
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }
    &__body{
        height: 1px;
        flex: 1;
        display: flex;
        background: var(--Gray-neutral-25, #FCFCFD);
        &__scrollview{
            height: 100%;
            flex: 1;
            overflow-y: scroll;
        }
        &__form{
            display: flex;
            // width: 680px;
            padding: 16px 48px 48px 48px;
            flex-direction: column;
            align-items: flex-end;
            gap: 32px;
            box-sizing: border-box;
        }
        &__console{
            width: 100%;
            height: 100%;
            flex: 1;
            display: inline-flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            flex-shrink: 0;
            background: var(--Gray-neutral-50, #F9FAFB);
            /* Shadow/lg */
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
            box-sizing: border-box;

            color: var(--Base-Black, #000);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            
            box-sizing: border-box;
            overflow-y: scroll;
        }
    }
}

.publication,
.google-scholar,
.awards,
.membership
{
    display: inline-flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex-shrink: 0;

    &__md{
        padding: 0;
        background-color: var(--Gray-neutral-50, #F9FAFB);

        color: var(--Base-Black, #000);
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
}



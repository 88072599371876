

.html_viewer h1{
    line-height: 1.5;
}

.html_viewer {
    position: relative;
    padding: 20px;

    .pdf_link {
        margin-top: 20px;
        text-align: center;

        a {
            color: #58968E;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

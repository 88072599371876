

.table{
    &__fullname{

        &__banner{
            display: flex;
            width: 40px;
            height: 40px;
            // padding: 8px 0px;
            box-sizing: border-box;
            // align-items: center;
            justify-content: center;
            align-items: center;

            border-radius: 200px;
            background: var(--Light-Green-15, #F2FAF9);
        }

        &__title{
            
        }
    }
}

.petition-letter{
    height: 100%;
}

.petition-letter article{
    padding: 32px;
    
    background-color: #fff;
    transform: scale(1);
    transform-origin: top center;
}

.petition-letter article h3 {
    color: #000;
    /* Text sm/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.petition-letter article h4 {
    color: #000;
    /* Text xs/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.petition-letter article p {
    color: #000;
    /* Text xs/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.petition-letter article li{
    color: #000;
    /* Text xs/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

// const pdfStyles = StyleSheet.create({
//     page: {
//         display: "flex",
//         flexDirection: 'column',
//         backgroundColor: '#fff',
//         transform: "scale(1)",
//         transformOrigin: "top center"
//     },
//     title: {
//         color: "#2A2A2A",
//         fontSize: 32,
//         fontWeight: 600,
//         lineHeight: 1.06,
//         padding: "54px 0px 6px 0px"

//     },
//     subTitle: {
//         color: "#2A2A2A",
//         fontSize: 24,
//         fontWeight: 600,
//         lineHeight: 1.08,
//         padding: "20px 0px 4px 0px"
//     },
//     section: {
//         color: "#2A2A2A",
//         fontSize: 18,
//         fontWeight: 400,
//         lineHeight: 1.44,
//         padding: "6px 0px"
//     }
// });

.highlight{
    background-color: rgba(254, 200, 75, 0.20);
}



.form{
    height: 100%;
    width: 100%;
    background: var(--Gray-neutral-25, #FCFCFD);
    

    &__header{
        padding: 20px 80px 20px 28px;
        height: 40px;

        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */

        /* Shadow/xs */
        background: var(--Base-White, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        z-index: 1;
    }

    &__wizard{
        height: 100%;
        width: 296px;

        background: var(--Colors-Base-white, #FFF);

        /* Shadow/lg */
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }

    &__content{
        height: 100%;
        padding: 24px 192px 48px 192px;
        box-sizing: border-box;
        overflow: scroll;
        scrollbar-width: thin;
    }
}

.wizard{

    &__title{
        padding: 24px 0px 24px 28px;
        border-bottom: 1px solid var(--Gray-true-100, #F5F5F5);

        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__subtitle{

        padding: 16px 10px 16px 28px;

        color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
        /* Text sm / Bold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    &__list{
        padding: 0px 16px;
        &__item{
            height: 48px;
            padding: 12px;
            box-sizing: border-box;
            border-radius: 8px;

            color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
            /* Text md/Medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */

            position: relative;
            cursor: pointer;

            &:hover{
                background: var(--Colors-Gray-true-50, #FAFAFA);
            }

            &_activate{
                background: var(--Light-Green-15, #F2FAF9);
            }

            &_finish{
                
            }

            &_disabled{
                border-radius: 8px;
                background: var(--Base-White, #FFF);
                color: var(--Gray-400, var(--colors-gray-light-mode-400, #98A2B3));
                /* Text md/Medium */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */

                &:hover{
                    background: var(--Base-White, #FFF);
                }
            }

            &__icon{
                position: absolute;
                right: 12px;
                width: 20px;
                height: 20px;
            }
        }
    }

}


.stepform{
    
    width: 760px;
    margin: auto;
    

    &__header{
        color: var(--Gray-900, #101828);
        /* Display sm/Semibold */
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 126.667% */
    }

    &__title{
        color: var(--Gray-900, #101828);
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__subtitle{
        color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}

.select-your-form{
    
    &__formtypes{

        &__title{
            color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
            /* Text md/Medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
        }

        &__subtitle{
            color: var(--Gray-600, var(--colors-gray-light-mode-600, #475467));
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }
}


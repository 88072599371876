

.swiper{

    width: 100%;
    height: 100%;
    overflow: hidden;

    &__container{
        position: relative;
        height: 100%;
        white-space: nowrap;
        transition: transform .2s;
    }

    &__item{
        width: 100%;
        height: 100%;
        position: absolute;
    }
}



.form{

    width: 480px;
    padding: 24px;
    box-sizing: border-box;

    border-radius: 12px;
    background: var(--Base-White, #FFF);

    &__header{
        color: var(--Gray-900, #101828);
        text-align: center;
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__body{

    }

    &__footer{
        padding: 16px 0px 24px 0px;
    }
}
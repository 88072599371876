


.chatbot__msg{
    
    &__citation{

        &-wrapper{
            display: inline-block;
        }

        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
    
        border-radius: 200px;
        background: var(--Light-Green-100, #AFE3DC);
    
        /* Text xs/Medium */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */

        cursor: pointer;
    
        &__reference{
             
            &__title{
                color: var(--Gray-900, #101828);
                /* Text md/Semibold */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */

                width: calc(350px - 8px - 16px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &__img{
                width: 16px;
                height: 16px;
            }
            
            &__url{
                color: var(--Light-Green-600, #36625D);
                /* Text sm/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                // line-height: 20px; /* 142.857% */

                cursor: pointer;
                width: 350px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
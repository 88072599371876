

.form-filling{
    width: 100%;
    padding: 24px 48px 48px 48px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    scrollbar-width: thin;

    &__processing{
        padding: 16px;

        border-radius: 12px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--Base-White, #FFF);

        &__title{
            color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
        }

        &__link{
            color: var(--Light-Green-500, #4C877F);
            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            
            cursor: pointer;
        }
    }

    &__table{

        &__header{
            height: 68px;
            // padding: 16px 24px;
            color: var(--Gray-900, #101828);
            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }
        
        &__empty{
            // display: inline-block;
            width: 720px;
            margin: auto;
            &__title{
                color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
                /* Text lg/Bold */
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px; /* 155.556% */
            }
    
            &__subtitle{
                color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
                text-align: center;
                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
        }

    }


    

}
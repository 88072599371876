
.homepage{
    position: relative;
    height: 100%;
    &__banner{
        height: 100%;
        background-image: url("./asests/Homepage.png") ;
        background-size: cover;
        background-position: center 0;
        background-repeat: no-repeat;

        &__logo{
            width: 623px;
            color: #000;
            text-align: center;

            /* Display xl/Bold */
            font-family: Inter;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 72px; /* 120% */
            letter-spacing: -1.2px;

            &__highlight{
                color: var(--green-old-green-300, #58968E);
                /* Display xl/Bold */
                font-family: Inter;
                font-size: 60px;
                font-style: normal;
                font-weight: 700;
                line-height: 72px;
                letter-spacing: -1.2px;
            }
        }
    }

    &__capabilities{
        padding: 160px 80px;
        box-sizing: border-box;

        &__label{
            color: var(--green-old-green-300, #58968E);
            text-align: center;

            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

        &__title{
            width: 840px;
            color: var(--Gray-900, #101828);
            text-align: center;

            /* Display md/Semibold */
            font-family: Inter;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px; /* 122.222% */
            letter-spacing: -0.72px;
        }

        &__desc{
            width: 840px;
            color: var(--Gray-600, #475467);
            text-align: center;

            /* Text xl/Regular */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 150% */
        }

        &__advantage{

            &__icon{
                
                width: 48px;
                height: 48px;
                box-sizing: border-box;

                border-radius: 10px;
                border: 1px solid var(--Gray-200, #EAECF0);
                background: var(--Base-White, #FFF);

                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            &__title{
                color: var(--Gray-900, #101828);
                text-align: center;

                /* Text xl/Semibold */
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px; /* 150% */
            }

            &__desc{
                color: var(--Gray-600, #475467);
                text-align: center;

                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
        }
    }


}
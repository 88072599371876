.document-upload{
    padding: 16px 24px;
    width: 100%;
    box-sizing: border-box;
    // width: 760px;
    // height: 126px;
    // overflow: hidden;
    
    border-radius: 12px;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--Base-White, #FFF);

    position: relative;

    &_fill{
        // max-height: 76px;
    }

    transition: max-height .1s;

    &__dragger{
        padding: 16px 24px;
        box-sizing: border-box;
        // height: 126px;

        // border-radius: 12px;
        // border: 1px solid var(--Gray-200, #EAECF0);
        // background: var(--Base-White, #FFF);

        color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &__a{
            color: var(--Light-Green-500, #4C877F);
            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
        }

        &__tips{
            color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
            text-align: center;

            /* Text xs/Regular */
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
        }
    }

    &__uploading{
        height: 76px;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 12px;
        border: 1px solid var(--Gray-200, #EAECF0);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
        display: flex;

        &__float{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
        
        &__filename{
            height: 20px;
            color: var(--Gray-700, #344054);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }

        &__filesize{
            height: 20px;
            color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }

    &__uploaded{
        height: 76px;
        padding: 16px;
        box-sizing: border-box;

        border-radius: 12px;
        background: var(--Colors-Base-white, #FFF);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);

        &__float{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
        
        &__filename{
            height: 20px;
            color: var(--Gray-700, #344054);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */

            &_readOnly{
                color: var(--Gray-400, var(--colors-gray-light-mode-400, #98A2B3));
            }
        }

        &__filesize{
            height: 20px;
            color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */

            &_readOnly{
                color: var(--Gray-400, var(--colors-gray-light-mode-400, #98A2B3));
            }
        }

        &__updatedAt{
            color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            &_readOnly{
                color: var(--Gray-400, var(--colors-gray-light-mode-400, #98A2B3));
            }
        }
    }
}
.document-upload__list{

}

.progress{
    width: 100%;
    &__bar{
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background: var(--Gray-200, #EAECF0);
        position: relative;

        &__scroller{
            width: 100%;
            border-radius: 4px;
            background: var(--Light-Green-400, #63ABA2);
            height: 8px;
            
            position: absolute;
            left: 0;
            top: 0;

            transition: max-width .1s;
        }
    }

    &__label{
        width: 36px;
        color: var(--Gray-700, #344054);
        /* Text sm/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
}



.link-group{
    
}

.link-item{

    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 8px;

    color: var(--Gray-600, #475467);
    /* Text md/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    cursor: pointer;
}
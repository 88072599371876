.case-filling{
    width: 100%;
    padding: 24px 48px 48px 48px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    scrollbar-width: thin;

    &__versiontips{
        padding: 16px;
        border-radius: 12px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--Colors-Base-white, #FFF);
        &__icon{
            display: flex;
            width: 40px;
            height: 40px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            box-sizing: border-box;

            border-radius: 8px;
            border: 1px solid var(--Gray-200, #EAECF0);
            background: var(--Colors-Base-white, #FFF);

            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &__title{
            color: var(--Gray-700, #344054);
            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
        }
        &__content{
            color: var(--Gray-600, #475467);

            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }

    &__table{

        &__header{
            height: 68px;
            // padding: 16px 24px;
            color: var(--Gray-900, #101828);
            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }
        
        &__empty{
            // display: inline-block;
            width: 720px;
            margin: auto;
            &__title{
                color: var(--Gray-700, var(--colors-gray-light-mode-700, #344054));
                /* Text lg/Bold */
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px; /* 155.556% */
            }
    
            &__subtitle{
                color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
                text-align: center;
                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
        }

    }

}


.status-tag{
    padding: 2px 10px;
    display: inline-block;
    border-radius: 16px;
    mix-blend-mode: multiply;

    
    text-align: center;
    /* Text sm/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    &_inprogress{
        background: var(--Warning-50, #FFFAEB);
        color: var(--Warning-700, #B54708);
    }

    &_completed{
        background: var(--Success-50, #ECFDF3);
        color: var(--Success-700, #027A48);

    }

}



.rb{

    &__modal{
        position: relative;
        border-radius: 20px;
        background: var(--Gray-neutral-25, #FCFCFD);
        height: 100%;
        padding: 20px 0;
        
        &-wrapper{
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding: 40px 70px;

            z-index: 999;
            visibility: hidden;

            &_visible{
                visibility: visible;
            }
            
        }
        &__mask{
            background: rgba(0, 0, 0, 0.01);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 1;

            &_visible{
                background: rgba(0, 0, 0, 0.15);
            }

            transition: background-color .3s;
        }

        &__header{
            // height: 56px;
            padding: 0 8px;

            &__close{
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }

        &__body{
            // padding: 0 10px;
            height: calc(100% - 40px);
            position: relative;
            
        }
    }

    &__confirm{
        position: relative;
        border-radius: 20px;
        // background: var(--Gray-neutral-25, #FCFCFD);
        height: 100%;
        width: 100%;

        &-wrapper{
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding: 40px 70px;

            z-index: 999;
            visibility: hidden;

            &_visible{
                visibility: visible;
            }
            
        }
        &__mask{
            background: rgba(255, 255, 255, 0);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 1;

            &_visible{
                background: rgba(68, 75, 86, 0.70);
            }

            transition: background-color .3s;
        }
        
    }
}
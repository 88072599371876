

// input[type=checkbox]:checked{
//     background-color: #4C877F !important;
// }

.form{
    &__itemgroup{
        &__title{
            color: var(--Gray-700, #344054);
            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }
    }

    &__item{
        &__label{
            color: var(--Gray-700, #344054);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */

            &_required{
                color: var(--Error-500, #F04438);
            }
        }
        &__error{
            color: var(--Error-500, #F04438);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            height: 20px;
            box-sizing: border-box;
        }
    }

    &__input{
        display: flex;
        padding: 10px 14px;
        width: 100%;
        box-sizing: border-box;
        outline: none;

        border-radius: 8px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--Colors-Base-white, #FFF);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: var(--Gray-900, #101828);
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        transition: border-color .2s, box-shadow .2s;

        &_number{
            padding: 6px 0px;
        }

        &::placeholder{
            color: var(--Gray-500, #667085);
        }

        &:focus{
            border-radius: 8px;
            border: 1px solid var(--Light-Green-300, #79D0C5);
            background: var(--Colors-Base-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E4F6F3;
            color: var(--Gray-900, #101828);
        }

        &_disabled{
            border-radius: 8px;
            border: 1px solid var(--Gray-300, #D0D5DD);
            background: var(--Gray-50, #F9FAFB);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            color: var(--Gray-500, #667085);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }

        &_error{
            border-radius: 8px;
            border: 1px solid var(--Error-500, #F04438);
            background: var(--Colors-Base-white, #FFF);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            &:focus{
                border-radius: 8px;
                border: 1px solid var(--Error-500, #F04438);
                background: var(--Colors-Base-white, #FFF);
                box-shadow: unset;
                // color: var(--Gray-900, #101828);
            }
        }

        &_readOnly{
            border-radius: 8px;
            border: 1px solid var(--colors-gray-light-mode-300, #D0D5DD);
            background: var(--Gray-50, #F9FAFB);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */

            &:focus{
                border-radius: 8px;
                border: 1px solid var(--colors-gray-light-mode-300, #D0D5DD);
                background: var(--Gray-50, #F9FAFB);
                box-shadow: unset;
                color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
            }

            &:hover{
                border-radius: 8px;
                border: 1px solid var(--colors-gray-light-mode-300, #D0D5DD);
                background: var(--Gray-50, #F9FAFB);
                box-shadow: unset;
                color: var(--Gray-500, var(--colors-gray-light-mode-500, #667085));
            }
        }
    }

    &__select{
        width: 100%;
        height: 46px;
    }

    &__checkbox{


        &__btn{
            width: 16px;
            height: 16px;

            border-radius: 4px;
            border: 1px solid var(--Gray-true-300, #D6D6D6);
            background: var(--Colors-Base-white, #FFF);
            outline: none;

            
        }

        &__label{
            color: var(--Gray-true-700, #424242);
            /* Text sm/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            vertical-align: middle;
        }
    }


    &__submit{
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;

        border-radius: 8px;
        border: 1px solid var(--Light-Green-500, #4C877F);
        background: var(--Light-Green-500, #4C877F);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: var(--Base-White, #FFF);
        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */

        cursor: pointer;
        outline: none;

        transition: background-color .2s, border-color .2s, box-shadow .2s;
        
        &:hover{
            border-radius: 8px;
            border: 1px solid var(--Light-Green-600, #36625D);
            background: var(--Light-Green-600, #36625D);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        &:disabled{
            border-radius: 8px;
            border: 1px solid var(--Light-Green-50, #C9ECE8);
            background: var(--Light-Green-25, #D7F1EE);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
    }
}

.loading{

    width: 422px;
    padding: 24px;

    border-radius: 12px;
    background: var(--Base-White, #FFF);

    &__icon{
        width: 64px;
        height: 64px;
    }

    &__title{
        color: var(--Gray-900, #101828);
        text-align: center;
        
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__content{
        color: var(--Gray-600, #475467);
        text-align: center;

        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}

.loading-icon{
    
    position: relative;

    &__label{
        position: absolute;
        z-index: 1;

        color: var(--Gray-700, #344054);
        text-align: center;

        /* Text sm/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
}
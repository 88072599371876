
.ant-popover-inner{
    padding: 8px !important;
}

.dropdown{

    background: var(--Colors-Base-white, #FFF);

    &__item{
        width: 200px;
        padding: 8px 16px;
        box-sizing: border-box;
        word-break: keep-all;
        overflow-x: hidden;
        text-overflow: ellipsis;

        color: #000;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        cursor: pointer;

        &:hover{
            border-radius: 8px;
            background: var(--Gray-50, #F9FAFB);
        }
    }
}


.delete-modal{
    
    width: 422px;
    box-sizing: border-box;

    border-radius: 12px;
    background: var(--Base-White, #FFF);

    &__body{
        padding: 24px 24px 0px 24px;
    }

    &__icon{
        display: flex;
        width: 48px;
        height: 48px;
        justify-content: center;
        align-items: center;

        border-radius: 48px;
        border: 8px solid var(--Error-50, #FEF3F2);
        background: var(--Error-100, #FEE4E2);

    }

    &__title{
        color: var(--Gray-900, #101828);
        text-align: center;
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__content{
        color: var(--Gray-600, #475467);
        text-align: center;
        
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    &__footer{
        padding: 32px 24px 24px 24px;
    }
}

.confirm-modal{
    
    width: 422px;
    box-sizing: border-box;

    border-radius: 12px;
    background: var(--Base-White, #FFF);

    &__body{
        padding: 24px 24px 0px 24px;
    }

    &__icon{
        display: flex;
        width: 48px;
        height: 48px;
        justify-content: center;
        align-items: center;

        border-radius: 28px;
        border: 8px solid var(--Success-50, #ECFDF3);
        background: var(--Success-100, #D1FADF);

    }

    &__title{
        color: var(--Gray-900, #101828);
        text-align: center;
        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
    }

    &__content{
        color: var(--Gray-600, #475467);
        text-align: center;
        
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    &__footer{
        padding: 32px 24px 24px 24px;
    }
}






.forgetpassword{
    width: 360px;
    padding-top: calc(184px / 960px * 100vh);

    &__icon{
        display: flex;
        width: 56px;
        height: 56px;
        padding: 14px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        border-radius: 28px;
        border: 10px solid var(--green-old-green-20, #EAF6F4);
        background: var(--green-old-green-40, #D5E9E6);
    }

    &__title{
        color: var(--Gray-900, #101828);
        text-align: center;
        /* Display sm/Semibold */
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 126.667% */
    }

    &__subtitle{
        color: var(--Gray-600, #475467);
        text-align: center;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

    }

    &__backtologin{
        color: var(--Gray-true-600, #525252);
        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }
}


.client-management{
    height: 100%;
    padding: 32px 32px;
    box-sizing: border-box;
    background: var(--Gray-neutral-25, #FCFCFD);

    &__title{
        color: var(--Gray-900, #101828);

        /* Display xs/Medium */
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 133.333% */
    }

    &__create-modal{
        width: 480px;
        padding: 24px;
        box-sizing: border-box;

        border-radius: 12px;
        background: var(--Base-White, #FFF);
    }

    

}

.expandable-icon{

    &_expand{
        transform: rotate(90deg);
    }

    transition: transform .2s;

}

.expandrow{
    overflow: hidden;
    &__label{
        color: var(--Gray-600, #475467);
        /* Text xs/Medium */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
    }

    &__empty{
        padding-left: 32px;
        color: var(--Gray-400, var(--colors-gray-light-mode-400, #98A2B3));
        /* Text sm/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
    }
}


.table__client{
    &__tools{
        &__label{
            color: #000;
            &_red{
                color: var(--Error-500, #F04438);
            }
        }
    }
}



.link{
    // display: inline-block;

    color: var(--green-old-green-300, #58968E);
    /* Text sm/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */

    cursor: pointer;

    &:hover{
        color: var(--green-old-green-300, #58968E);
    }
}



.essential-steps{

    &__item{

        padding: 24px 16px;
        border-radius: 12px;
        border: 1px solid var(--Gray-200, #EAECF0);
        background: var(--Base-White, #FFF);

        cursor: pointer;

        &__idx{
            color: var(--Gray-500, #667085);

            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

        &__title{
            color: var(--Gray-700, #344054);

            /* Text md/Semibold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

        &__subtitle{
            color: var(--Gray-700, #344054);

            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }
}


.button{
    
    padding: 10px 18px;
    box-sizing: border-box;
    text-align: center;

    cursor: pointer;
    transition: background-color .2s, border-color .2s, box-shadow .2s;
    outline: none;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    &_mini{
        padding: 8px 14px;

        font-size: 14px;
        line-height: 20px; /* 150% */
    }
    
    &_primary{
        border-radius: 8px;
        border: 1px solid var(--Light-Green-500, #4C877F);
        background: var(--Light-Green-500, #4C877F);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: var(--Base-White, #FFF);
        /* Text md/Semibold */
        // font-family: Inter;
        // font-size: 16px;
        // font-style: normal;
        // font-weight: 600;
        // line-height: 24px; /* 150% */
        
        &:hover{
            border-radius: 8px;
            border: 1px solid var(--Light-Green-600, #36625D);
            background: var(--Light-Green-600, #36625D);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        &:focus{
            border-radius: 8px;
            border: 1px solid var(--Light-Green-500, #4C877F);
            background: var(--Light-Green-500, #4C877F);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #63ABA2;
        }

        &_disabled{
            border-radius: 8px;
            border: 1px solid var(--Light-Green-50, #C9ECE8);
            background: var(--Light-Green-25, #D7F1EE);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            &:hover{
                border-radius: 8px;
                border: 1px solid var(--Light-Green-50, #C9ECE8);
                background: var(--Light-Green-25, #D7F1EE);
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            &:focus{
                border-radius: 8px;
                border: 1px solid var(--Light-Green-50, #C9ECE8);
                background: var(--Light-Green-25, #D7F1EE);
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }
    }

    &_whitebg{

        border-radius: 8px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--Colors-Base-white, #FFF);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: var(--Gray-700, #344054);
        /* Text md/Semibold */
        // font-family: Inter;
        // font-size: 16px;
        // font-style: normal;
        // font-weight: 600;
        // line-height: 24px; /* 150% */

        &:hover{
            border-radius: 8px;
            border: 1px solid var(--Gray-300, #D0D5DD);
            background: var(--Gray-50, #F9FAFB);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        &:focus{
            border-radius: 8px;
            border: 1px solid var(--Gray-300, #D0D5DD);
            background: var(--Base-White, #FFF);
            /* Shadow/xs focused 4px gray-100 */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;
        }

        &_focus{
            border-radius: 8px;
            border: 1px solid var(--Gray-300, #D0D5DD);
            background: var(--Base-White, #FFF);
            /* Shadow/xs focused 4px gray-100 */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;
        }
    
        &_disabled{
            border-radius: 8px;
            border: 1px solid var(--Light-Green-50, #C9ECE8);
            background: var(--Light-Green-25, #D7F1EE);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            &:hover{
                border-radius: 8px;
                border: 1px solid var(--Light-Green-50, #C9ECE8);
                background: var(--Light-Green-25, #D7F1EE);
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            &:focus{
                border-radius: 8px;
                border: 1px solid var(--Light-Green-50, #C9ECE8);
                background: var(--Light-Green-25, #D7F1EE);
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }

    }

    &_blackbg{
        // padding: 8px 28px;

        border-radius: 8px;
        background: var(--Base-Black, #000);

        color: var(--Base-White, #FFF);

        /* Text lg/Semibold */
        // font-family: Inter;
        // font-size: 18px;
        // font-style: normal;
        // font-weight: 600;
        // line-height: 28px; /* 155.556% */

    }

    &_redBg{
        // padding: 8px 28px;

        border-radius: 8px;
        border: 1px solid var(--Error-600, #D92D20);
        background: var(--Error-600, #D92D20);
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: var(--Base-White, var(--Colors-Base-white, #FFF));
        /* Text md/Semibold */
        // font-family: Inter;
        // font-size: 16px;
        // font-style: normal;
        // font-weight: 600;
        // line-height: 24px; /* 150% */


        &:hover{
            border-radius: 8px;
            border: 1px solid var(--Error-700, #B42318);
            background: var(--Error-700, #B42318);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }


    }

    &_transparentBg{

        border-radius: 0;
        border: 0px;
        background: transparent;
        /* Shadow/xs */
        // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: var(--Gray-600, #475467);
        /* Text md/Semibold */
        // font-family: Inter;
        // font-size: 16px;
        // font-style: normal;
        // font-weight: 600;
        // line-height: 24px; /* 150% */
    }
    
}
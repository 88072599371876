


.advantage{
    display: flex;
    // width: 616px;
    height: 550px;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
    justify-content: space-between;

    border-radius: 13px;
    background: #F2F8F7;

    &__header{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }

    &__icon{
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid var(--green-old-green-50, #D0E4E1);
        background: #FFF;
    }

    &__title{
        color: #1A1A19;
        /* Text xl/Semibold */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
    }

    &__desc{
        color: var(--Gray-600, #475467);

        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }

    &__bg{
        width: 100%;
        height: 288px;
        background-repeat: no-repeat;
        background-size: auto 100%;
        &_0{
            background-image: url(./asests//adv/pic1.png);

        }

        &_1{
            background-image: url(./asests//adv/pic2.png);
        }

        &_2{
            background-image: url(./asests//adv/pic3.png);
        }

        &_3{
            background-image: url(./asests//adv/pic4.png);
        }
    }
}
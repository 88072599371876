

.formgroup{

    &__title{
        color: var(--Gray-700, #344054);
        /* Text md/Semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

    &__subtitle{
        color: var(--Gray-600, #475467);
        /* Text sm/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    
}
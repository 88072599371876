
:root{

    --screen-chat-width: 920px;
    --screen-chat-msg-default-width: 100%;
    --screen-chat-msg-text-width: 720px;
    --screen-chat-msg-qa-width: 100%;
    --screen-chat-msg-bulletin-width: 100%;
    --screen-chat-msg-resourcelist-width: 100%;
    --screen-chat-msg-documentlink-width: 100%;

    --modal-chat-width: calc(100% - 40px);
    --modal-chat-msg-text-width: 960px;
    --modal-chat-msg-qa-width: 100%;
    --modal-chat-msg-bulletin-width: 100%;
    --modal-chat-msg-resourcelist-width: 100%;
    --modal-chat-msg-documentlink-width: 100%;

}

.chatbot{
    
    height: 100%;
    background: var(--Gray-neutral-25, #FCFCFD);
    
    &__sider{
        height: 100%;
        width: 280px;
        box-sizing: border-box;

        display: flex;
        padding: 16px 21px 0px 11px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background: var(--Gray-100, #F2F4F7);
    }

    &__content{
        // padding-top: 40px;
        &-container{
            position: relative;
            height: 100%;
            flex: 1;
        }

        &-scrollview{
            overflow-x: hidden;
            overflow-y: scroll;
            height: 100%;   
            margin: auto;
            // &::-webkit-scrollbar{
            //     display: none;
            // }
            scrollbar-width: thin;
        }
        // width: var(--chat-screen-width);
        // padding-top: 43px;
        // overflow-y: scroll;

        &__header{
            height: 40px;
            position: absolute;
            left: 0;
            right: 16px;
            top: 0;
            background: var(--Gray-neutral-25, #FCFCFD);
            z-index: 1;
        }

        &__body{
            padding-bottom: 200px;
            // width: var(--chat-screen-width);
        }
    
        &__footer{
            position: absolute;
            left: 0;
            right: 16px;
            bottom: 0;
            overflow:auto;
    
            // height: 168px;
            // background: var(--Gray-neutral-25, #FCFCFD);
            // border-radius: 20px;
            // background: linear-gradient(180deg, rgba(252, 252, 253, 0.00) -9.51%, #FCFCFD 10.74%);
            background: var(--Gray-neutral-25, #FCFCFD);
            &__container{
                margin: 0 16px;
                background: var(--Gray-neutral-25, #FCFCFD);
            }
        }
    }

    &__header, &__body{
        margin: auto;
        // width: var(--chat-screen-width);
    }

    &__header{
        padding-top: 40px;
    }

    &__title{

        &__logo{
            width: 56px;
            height: 56px;
            border-radius: 140px;
        }

        &__title{
            color: var(--Gray-900, #101828);
            text-align: center;
            
            /* Display xs/Bold */
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */

            
        }

        &__subTitle{
            color: var(--Gray-600, #475467);

            /* Text lg/Regular */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 155.556% */
        }
    }

    &__shortcuts{
        
    }

    &__shortcut{

        padding: 16px;

        border-radius: 16px;
        border: 1px solid var(--Gray-neutral-200, #E5E7EB);
        background: #FFF;

        cursor: pointer;

        &:hover{
            border: 1px solid var(--Gray-neutral-200, #E5E7EB);
            background: var(--Gray-neutral-100, #F3F4F6);
        }

        transition: border .2s,background-color .2s;

        &__title{
            color: var(--Gray-900, #101828);
            /* Text md/Medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
        }

        &__subTitle{
            color: var(--Gray-600, #475467);
            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }

    &__input{
        height: 100%;
        width: var(--chat-screen-width);
        margin: auto;
        padding-top: 16px;
        padding-bottom: 48px;

        &__tips{
            height: 40px;
            padding-right: 64px;
        }

        &__tip{
            cursor: pointer;
            display: flex;
            padding: 8px 16px;
            border-radius: 8px;
            border: 1px solid var(--green-old-green-300, #58968E);
            background: var(--Base-White, #FFF);

            color: var(--green-old-green-300, #58968E);
            /* Text md/Bold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */

            &:hover{
                border-radius: 8px;
                border: 1px solid var(--green-old-green-300, #58968E);
                background: var(--green-old-green-20, #EAF6F4);
            }

            transition: background-color .2s;
        }

        &__input{
            padding: var(--spacing-xl, 16px) 14px;
            // display: flex;
            // align-items: center;
            // gap: 8px;
            // flex: 1 0 0;
            outline: none;
            border-radius: var(--radius-md, 8px);
            background: var(--Base-White, #FFF);
            border: 1px solid var(--Gray-300, #D0D5DD);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);

            color: var(--Base-Black, #000);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */

            &:focus{
                border: 1px solid var(--Light-Green-300, #79D0C5);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E4F6F3;
            }

            &::placeholder{
                color: #667085;
            }

            &:disabled{

            }

            transition: box-shadow .2s;
        }

        &__btn{
            cursor: pointer;
            width: 56px;
            height: 56px;
            // padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            border: 1px solid var(--green-old-green-300, #58968E);
            background: var(--green-old-green-300, #58968E);
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            &__icon{
                width: 24px;
                height: 24px;
            }

            &_disabled{
                border: 1px solid var(--green-old-green-20, #EAF6F4);
                background: var(--green-old-green-300, #EAF6F4);
            }

            transition: background-color .2s;
        }

        &__warn{
            color: var(--Gray-500, #667085);
            /* Text xs/Regular */
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
        }
    }

    &__msg{
        
        // padding: 16px;

        &_ai, &_system{
            // border-radius: 0px 20px 20px 20px;
            // border: 1px solid var(--Gray-neutral-400, #9DA4AE);
            // background: var(--Base-White, #FFF);
            color: var(--Base-Black, #000);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            width: 100%;
        }

        &_human{
            border-radius: 20px 0px 20px 20px;
            background: var(--Light-Green-400, #63ABA2);

            color: var(--Base-White, #FFF);
            /* Text md/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }

        &_text{
            // max-width: var(--chat-msg-text-width);
            word-break: normal;
            &_human{
                padding: 16px;
                word-break: break-word;
                &_modal{
                    max-width: var(--modal-chat-msg-text-width);
                }

                &_screen{
                    max-width: var(--screen-chat-msg-text-width);
                }
            }
        }

        &_qa{
            // max-width: var(--chat-msg-qa-width);
            &__text{
                color: var(--Base-Black, #000);
                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
            &__relatedTopics{
                &__title{
                    color: var(--Base-Black, #000);
                    /* Text md/Medium */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 150% */
                }
            }
            &__relatedTopic{
                display: flex;
                padding: 8px 14px;
                align-items: flex-start;
                gap: 8px;

                border-radius: var(--radius-md, 8px);
                border: 1px solid var(--Gray-300, #D0D5DD);
                background: var(--Gray-50, #F9FAFB);
                cursor: pointer;

                color: var(--Base-Black, #000);
                /* Text sm/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
            }
        }

        &_visabulletin{
            &__table{
                // border-radius: 12px;
                // border: 1px solid var(--Light-Green-100, #AFE3DC);
                // background: #FFF;
            }
            // width: var(--chat-msg-bulletin-width);
            &__updateTime{
                color: var(--Base-Black, #000);
                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
        }

        &_resourcelist{
            // width: var(--chat-msg-resourcelist-width);
        }

        &_resource{
            &__divider{
                height: 1px;
                background-color: var(--Gray-neutral-200, #E5E7EB);
            }
            &__action{
                padding: 10px 16px;
                border-radius: 8px;

                color: var(--Gray-600, #475467);
                /* Text sm/Semibold */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */

                cursor: pointer;

                &__icon{
                    width: 20px;
                    height: 20px;
                }

                &__modal{
                    height: 100vh;
                    // padding: 40px 72px;
                }
            }
        }

        &_documentlink{
            // width: var(--chat-msg-documentlink-width);
            // width: 840px;
        }

        &__feedback{

            &__thumbup, &__thumbdown{

                cursor: pointer;
                
                background: center / 20px no-repeat url('./assets/images/thumbs-up.svg');
                border-radius: 100px;
                &_activate{
                    background: center / 20px no-repeat url('./assets/images/thumbs-up-active.svg');
                }

                &:hover{
                    background: center / 20px no-repeat url('./assets/images/thumbs-up-active.svg'), var(--green-old-green-20, #EAF6F4);
                }

                transition: background-color .2s;
                padding: 8px;
                width: 36px;
                height: 36px;

                &__img{
                    width: 20px;
                    height: 20px;
                    
                }
            }

            &__thumbdown{

                background: center / 20px no-repeat url('./assets/images/thumbs-down.svg');
                &_activate{
                    background: center / 20px no-repeat url('./assets/images/thumbs-down-active.svg');
                }
                &:hover{
                    background: center / 20px no-repeat url('./assets/images/thumbs-down-active.svg'), var(--green-old-green-20, #EAF6F4);
                }
            }

        }

    }

    &__loading{
        &-box{
            display: flex;
            width: 76px;
            height: 56px;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
        }

        padding: 10px;

        border-radius: 0px var(--radius-md, 8px) var(--radius-md, 8px) var(--radius-md, 8px);
        background: var(--Base-White, #FFF);

        &__ball_1{
            width: 4px;
            height: 4px;
        }

        &__ball_2{
            width: 4px;
            height: 4px;
        }

        &__ball_3{
            width: 4px;
            height: 4px;
        }

    }

    &__sessionbar{
        height: 100%;
        padding: 16px;
        width: 280px;
        box-sizing: border-box;
        background: var(--Gray-100, #F2F4F7);
        position: relative;
        overflow: hidden;
        max-width: 280px;

        &_hidden{
            max-width: 0;
            padding: 16px 0px 0px 0px;
        }

        transition: max-width .3s, padding-left .3s, padding-right .3s;

        &-wrapper{
            position: relative;
            height: 100%;
            overflow: hidden;
        }

        &__create{
            
            width: 248px;
            height: 48px;
            padding: 8px;
            box-sizing: border-box;

            border-radius: 8px;
            border: 1px solid var(--Gray-300, #D0D5DD);
            cursor: pointer;

            &:hover{
                background: var(--Base-White, #FFF);
            }

            transition: background-color .2s;

            &__title{
                color: var(--Gray-900, #101828);
                /* Text md/Medium */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
            }

            &__icon{
                width: 24px;
                height: 24px;
            }
        }
        
        &__sesssions{
            width: 248px;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar{
                display: none;
            }
            scrollbar-width: 0px;
        }

        &__session{
            width: 100%;
            height: 40px;
            padding: 8px;
            box-sizing: border-box;

            border-radius: 8px;
            cursor: pointer;

            &:hover{
                background: var(--Base-White, #FFF);
            }

            &_activate{
                background: var(--Base-White, #FFF);
            }

            transition: backgroundColor .2s;

            &__title{
                color: var(--Gray-900, #101828);
                /* Text sm/Medium */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
    
                white-space:nowrap;
                overflow: hidden;
            }

            &__toolbarIcon{
                width: 24px;
                height: 24px;
            }

            &__toolbar{
                // padding: 8px;
                width: 200px;
            }

            &__tool{
                // width: 200px;
                padding: 8px;
                box-sizing: border-box;
                cursor: pointer;
                
                border-radius: 8px;
                    
                color: #000;
                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                
                &:hover{
                    background: var(--Gray-50, #F9FAFB);
                }

                &__icon{
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &__togglebar{
        // position: absolute;
        width: 24px;
        height: 100%;
        // left: 100%;
        // top: 0;
        // bottom: 0;
        
        display: flex;
        align-items: center;
        justify-content: center;

        &_opened{
            width: 24px;
            height: 24px;

            cursor: pointer;
        }

        &_hidden{
            width: 24px;
            height: 24px;

            cursor: pointer;
        }
    }
    
    &__session-delete-confirm{

        width: 422px;
        padding: 24px 24px 0px 24px;

        border-radius: 12px;
        background: var(--Base-White, #FFF);

        &__icon{
            width: 48px;
            height: 48px;
        }

        &__title{
            color: var(--Gray-900, #101828);
            text-align: center;

            /* Text lg/Semibold */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
        }

        &__desc{
            color: var(--Gray-600, #475467);
            text-align: center;

            /* Text sm/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }

        &__btns{
            padding: 32px 24px 24px 24px;
            gap: 12px;
        }

        &__btn{
            display: flex;
            padding: 8px 14px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            cursor: pointer;
        }

        &__cancel{
            border-radius: 8px;
            border: 1px solid var(--Gray-300, #D0D5DD);
            background: var(--Base-White, #FFF);

            color: var(--Gray-900, #101828);
            /* Text lg/Semibold */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
        }

        &__delete{
            border-radius: 8px;
            background: var(--Error-600, #D92D20);

            color: var(--Base-White, #FFF);
            /* Text lg/Semibold */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
        }
    }
}


.chatbot__content__body table{
    /* border-collapse: collapse; */
    border-collapse:separate;
    border: 1px solid #AFE3DC;
    /* font-family: sans-serif; */
    font-size: 14px;
    line-height: 24px;
    border-radius: 12px;
    /* letter-spacing: 1px; */
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
}

.chatbot__content__body caption {
    caption-side: bottom;
    text-align: left;
    padding: 10px;
    /* font-weight: bold; */
}

.chatbot__content__body thead,
.chatbot__content__body tfoot {
  background-color: #F2FAF9;
}

.chatbot__content__body tr{
   display: table-row;
}

.chatbot__content__body th,
.chatbot__content__body td {
    border: 0px solid #AFE3DC;
    padding: 8px 10px;
    text-align: start;
    /* flex: 1; */
  }


  .chatbot__content__body thead tr th:first-child{
    border-top-left-radius: 12px;
  }
  
  .chatbot__content__body thead tr th:last-child{
    border-top-right-radius: 12px;
  }

  .chatbot__content__body thead th, 
  .chatbot__content__body tbody th, 
  .chatbot__content__body td {
    text-align: center;
    border-right: 1px solid var(--Light-Green-100, #AFE3DC);
    border-bottom: 1px solid var(--Light-Green-100, #AFE3DC);
  }
  .chatbot__content__body thead th:last-child, 
  .chatbot__content__body td:last-child {
      border-right: 0;
  }
  .chatbot__content__body thead th:last-child, 
  .chatbot__content__body tr:last-child td, 
  .chatbot__content__body tr:last-child th{
      border-bottom: 0;
  }

  .chatbot__content__body  tbody {
    margin-bottom: 10px;
  }

  .chatbot__content__body thead tr th:nth-child(1) {
    width: 217px;
    /* min-width: 217px;
    max-width: 217px;
    flex-grow: 0;
    flex-shrink: 0; */
  }

  .chatbot__content__body tbody tr th:nth-child(1) {
    width: 217px;
    /* min-width: 217px;
    max-width: 217px;
    flex-grow: 0;
    flex-shrink: 0; */
  }
  